import React from "react";
import {
  AnalyticsIcon,
  DashboardIcon,
  DashboardIcon2,
  HistoryIcon,
  Icon99,
  IntegrationIcon,
  LogoutIcon,
  MessageIcon,
  SettingsIcon,
  SignInIcon,
  SignUpIcon,
  StatisticsIcon,
  StatisticsIcon2,
  SupportTicketIcon,
  TransactionIcon,
  UserIcon,
  UserIcon2,
  WalletIcon,
} from "../../assets/svgs";

import {useNavigate} from 'react-router-dom'
import logoShort from "../../assets/images/logo/logo-short.svg";
import logoShortWhite from "../../assets/images/logo/logo-short-white.svg";

export default function ResponsiveSideBar() {
  return (
    <aside class="sm:block hidden relative w-[96px] bg-white dark:bg-black">
      <div class="w-full sidebar-wrapper-collapse relative top-0 z-30">
        <div class="sidebar-header bg-white dark:bg-darkblack-600 dark:border-darkblack-500 sticky top-0 border-r border-b border-r-[#F7F7F7] border-b-[#F7F7F7] w-full h-[108px] flex items-center justify-center z-20">
          <a href="/dashboard/user">
            <img
              src={`${window.location.origin}/icon.png`}
              style={{ width: 40 }}
              className="dark:hidden block"
              alt="logo"
            />
            <img
              src={logoShortWhite}
              className="hidden dark:block"
              alt="logo"
            />
          </a>
        </div>
        <div class="sidebar-body pt-[14px] w-full">
          <div class="flex flex-col items-center">
            <div class="nav-wrapper mb-[36px]">
              <div class="item-wrapper mb-5">
                <List1 />
              </div>
            </div>
            <div class="upgrade-wrapper">
              <h4 className="text-sm font-medium text-bgray-700 dark:text-bgray-50 border-b border-bgray-200 dark:border-darkblack-400"></h4>
              <div class="w-10 flex justify-center items-center h-10 rounded-full border border-white">
                <LogoutIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

const List1 = () => {
  const navigate = useNavigate();

  return (
    <ul className="mt-2.5 flex justify-center items-center flex-col">
      <li className="item py-[11px] px-[43px]" onClick={() =>{ navigate('/dashboard/user')}}>
        <a  >
          <span className="item-ico">
            <UserIcon2 />
          </span>
        </a>
      </li>
      <li className="item py-[11px] px-[43px]" onClick={() => {navigate('/dashboard/couponGenerator')}}>
        <a >
          <span className="item-ico">
            <StatisticsIcon2 />
          </span>
        </a>
       
      </li>
      <li className="item py-[11px] px-[43px]"onClick={() => {navigate('/dashboard/articles-to-post')}}>
        <a >
          <span className="item-ico">
            <DashboardIcon2 />
          </span>
        </a>
      </li>
      <li className="item py-[11px] px-[43px]"onClick={() => {navigate('/dashboard/site-setting')}}>
        <a >
          <span className="item-ico">
            <StatisticsIcon2 />
          </span>
        </a>
      </li>
    </ul>
  );
};
