import React, { useState } from 'react';
import Field from '../Components/Common/Field';
import { toast } from "react-hot-toast";

import './gen.css'
const CouponGenerator = () => {
    const [productName, setProductName] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [coupon, setCoupon] = useState('');

    const [formData, setFormData] = useState({
        celebriVip: '',
        discountOfPercentage: '',
        durationOfMonth: '',
        cuponRestition: '',
        limitRedemptions: '',
        expirationDate: '',
        firstTimeOnly: false
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        
        console.log(e.target.value)
        const { name, value } = e.target;
        if (name == 'celebriVip') {
                setFormData({
                    ...formData,
                    [name]: value
                });
            
        } else if (name === 'expirationDate') {
            const selectedDate = new Date(value);
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set the time to midnight to ensure comparison is accurate

            if (selectedDate > today) {
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const generateCoupon = () => {
        const newErrors = {};
        if (!formData.discountOfPercentage) {
            newErrors.discountOfPercentage = 'Discount Percentage is required';
        }
        if (!formData.celebriVip) {
            newErrors.celebriVip = 'Celebri Vip is required';
        }
        if (!formData.cuponRestition) {
            newErrors.cuponRestition = 'Coupon Restriction is required';
        }

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return; // Prevent form submission if there are errors
        }

        addCupon(formData)
        const formattedCoupon = `${productName.toUpperCase()}${discountPercentage}`;
        setCoupon(formattedCoupon);
    };

    const buttonStyle = {
        margin: '10px',
        padding: '10px 20px',
        fontSize: '18px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };


    const addCupon = (data) => {
        setErrors({})
        fetch(`https://celebri-backend.onrender.com/create/coupon`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",

            },
            body: JSON.stringify({
                durationMonths: data.durationOfMonth,
                couponRestriction: data.cuponRestition,
                percentOff: data.discountOfPercentage,
                promotionCode: data.celebriVip,
                limitRedemptions: data.limitRedemptions,
                firstTimeOnly: data.firstTimeOnly,
                expirationDate: data.expirationDate,
                "role": "super_Admin"



            }),

        })
            .then((response) => response.json())
            .then((res) => {
                if (res.message === 'Coupon and promotion code created successfully') {
                    toast.success("Coupon and promotion code created successfully");
                }
                else {
                    toast.error(res.message);
                }
            })
            .catch((error) => {

            });
    };
    return (
        <div style={{ marginTop: "120px" }}>
            <div className="login-form-wrap">
                <h1 className="login-heading">Coupon Generator</h1>

                <Field placeholder="Promotion Code" name="celebriVip"
                    value={formData.celebriVip}
                    onChange={handleChange}

                />
                {errors.celebriVip && <span className="text-red-600 text-sm mb-3">{errors.celebriVip}</span>}
                <Field placeholder="Discount Percentage" name="discountOfPercentage" type={'number'}
                    value={formData.discountOfPercentage}
                    onChange={handleChange} />
                {errors.discountOfPercentage && <span className="text-red-600 text-sm mb-3">{errors.discountOfPercentage}</span>}

                <Field placeholder="Duration of month" type={'number'} name="durationOfMonth"
                    value={formData.durationOfMonth}
                    onChange={handleChange}

                />


                <select
                    name="cuponRestition"
                    value={formData.cuponRestition}
                    onChange={handleChange}
                // className={errors.selectedCar ? 'error' : ''} // Apply error class if there's an error
                >
                    <option value="">Select a coupon Restriction</option>
                    <option value="once">once</option>
                    <option value="repeating">repeating</option>
                    <option value="forever">forever</option>
                </select>
                {errors.cuponRestition && <span className="text-red-600 text-sm mb-3">{errors.cuponRestition}</span>}


                <Field placeholder="Limit Redemptions" name="limitRedemptions" type={'number'}
                    value={formData.limitRedemptions}
                    onChange={handleChange} />
                <Field placeholder="Expiration date" type={'date'} name="expirationDate"
                    value={formData.expirationDate}
                    onChange={handleChange} />

                <div className='flex items-center gap-3'>
                    <input type="checkbox" id="horns" name="horns" />
                    <label for="horns">First time only</label>
                </div>

                <br />
                <button style={buttonStyle} onClick={generateCoupon}>
                    Generate Coupon
                </button>
                <div style={{ marginTop: '20px', fontSize: '20px' }}>
                    {coupon && <strong>Your Coupon: {coupon}</strong>}
                </div>
            </div>
        </div>
    );
};

export default CouponGenerator;
