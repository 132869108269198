import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./assets/css/slick.css";
import "./assets/css/aos.css";
import "./assets/css/output.css";
import "./assets/css/style.css";
import SignIn from "./Pages/Signin";
import Page404 from "./Components/Common/Page404";
import Home from "./Components/Dashboard/Pages/Home";
import Stats from "./Components/Dashboard/Pages/Stats";
import User from "./Components/Dashboard/Pages/User";
import { Toaster } from "react-hot-toast";
import DashboardAdmin from "./Pages/DashboardAdmin";
import EditProfile from "./Pages/EditProfile";
import PaymentVerification from "./Pages/PaymentVerification";
import ViewPayments from "./Pages/ViewPayments";
import Annoucement from "./Pages/Annoucement";
import CouponGenerator from "./Pages/CouponGenerator";
import ArticlesToPost from './Pages/ArticlesToPost'
import PrivateRoutes from "./PrivateRoute";
import SiteSetting from './Pages/SiteSetting'

function App() {


  return (
    <div className="App">
      <Toaster />
      <Routes>
        <Route element={<PrivateRoutes/>}>
        <Route path="/dashboard" element={<DashboardAdmin />}>
          <Route path="app" element={<Home />} />
          <Route path="cms" element={<Stats />} />
          <Route path="user" element={<User />} />
          <Route path="viewPayments" element={<ViewPayments />} />
          <Route path="annoucement" element={<Annoucement />} />
          <Route path='couponGenerator' element={<CouponGenerator/>}/>
          <Route path='site-setting' element={<SiteSetting/>}/>
          <Route path='articles-to-post' element={<ArticlesToPost />} />
        </Route>
        </Route>

        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;
