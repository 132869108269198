import React, { useState,useEffect } from 'react';
import Field from '../Components/Common/Field';
import { toast } from "react-hot-toast";

import './gen.css'
const CouponGenerator = () => {
    const [Loading, setLoading] = useState(false);
    const [CurrentPageType, setCurrentPageType] = useState('');
    const [coupon, setCoupon] = useState('');

    useEffect(()=>{
       checkCurrentState() 
    },[])

    const [formData, setFormData] = useState({
        celebriVip: '',
        discountOfPercentage: '',
        durationOfMonth: '',
        cuponRestition: '',
        limitRedemptions: '',
        expirationDate: '',
        firstTimeOnly: false
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        console.log(e.target.name)
        const { name, value } = e.target;
        if (name === 'celebriVip') {
            if (value === '' || (/^\d+$/.test(value) && value <= 100)) {
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else if (name === 'expirationDate') {
            const selectedDate = new Date(value);
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set the time to midnight to ensure comparison is accurate

            if (selectedDate > today) {
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const generateCoupon = () => {
        const newErrors = {};
      
        if (!formData.cuponRestition) {
            newErrors.cuponRestition = 'Site type is required';
        }

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return; // Prevent form submission if there are errors
        }

        addCupon(formData)
        setErrors({})
    };

    const buttonStyle = {
        margin: '10px',
        padding: '10px 20px',
        fontSize: '18px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };


    const addCupon = (data) => {
        fetch(`https://celebri-backend.onrender.com/admin/pageType`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",

            },
            body: JSON.stringify({
                type: data.cuponRestition,
                "role": "super_Admin"



            }),

        })
            .then((response) => response.json())
            .then((res) => {
                if (res.success == true) {
                    toast.success(res.message);
                    checkCurrentState()
                }
                else {
                    toast.error(res.message);
                }
            })
            .catch((error) => {

            });
    };

    const checkCurrentState = () => {
        setLoading(true)
        fetch(`https://celebri-backend.onrender.com/users/pageState`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((res) => {
            console.log(res);
            if (res.success === true) {
              if (res.currentPageType ) {
                // navigate('/soon', { replace: true })
                setCurrentPageType(res.currentPageType || '');
                setFormData({
                    celebriVip: '',
                    discountOfPercentage: '',
                    durationOfMonth: '',
                    cuponRestition: res.currentPageType || '',
                    limitRedemptions: '',
                    expirationDate: '',
                    firstTimeOnly: false
                })
                console.log(res.currentPageType)
                setLoading(false);
    
              } else {
                setLoading(false);
              }
            } else {
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      };

    return (
        <div style={{ marginTop: "120px" }}>
              {Loading ? (
                    <div class="flex justify-center items-center h-min">
                      <div class="loader-container">
                        <div class="loader"></div>
                      </div>
                    </div>
                  ) : (
            <div className="login-form-wrap">
                <h1 className="login-heading">Site Setting</h1>

                <select
                    name="cuponRestition"
                    value={formData.cuponRestition}
                    onChange={handleChange}
                // className={errors.selectedCar ? 'error' : ''} // Apply error class if there's an error
                >
                    <option value="">Select a site setting</option>
                    <option value="maintain">Maintain</option>
                    <option value="simple">Simple</option>
                    <option value="soon">Coming Soon</option>
                    <option value="disable">Disable</option>
                </select>
                {errors.cuponRestition && <span className="text-red-600 text-sm mb-3">{errors.cuponRestition}</span>}



                <br />
                <button style={buttonStyle} onClick={generateCoupon}>
                    Apply
                </button>
                <div style={{ marginTop: '20px', fontSize: '20px' }}>
                    {coupon && <strong>Your Coupon: {coupon}</strong>}
                </div>
            </div>
                  )}
        </div>
    );
};

export default CouponGenerator;
