import axios from "axios";
import { useMediaQuery } from "react-responsive";

export const useIsMobile = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return isMobile;
};



const instance = axios.create({
  baseURL: "http://localhost:5000",
  headers: {
    Authorization: "Bearer" + " " + localStorage.getItem('token'), // Add token from localStorage or empty string if not available
  },
});




export const baseURL = "http://localhost:5000"


export default instance;
