import React from "react";

export default function Stats() {
  return (
    <main className="w-full xl:px-12 px-6 pb-6 xl:pb-12 sm:pt-[156px] pt-[100px]">
      <div className="2xl:flex 2xl:space-x-[48px]">
        <section class="2xl:flex-1 w-full">
          <div
            class="bg-white dark:bg-darkblack-600 rounded-lg mb-12"
            style={{ boxShadow: "0 0 8px 0 rgba(0,0,0,0.1)" }}
          >
            <div class="flex px-5 py-3 justify-between items-center border-b border-bgray-300 dark:border-darkblack-400">
              <h3 class="text-bgray-900 dark:text-white text-xl font-bold">
                Section 1
              </h3>
            </div>
            <div class="px-[55px] py-6">
              <div class="flex space-x-8 items-end mb-4">
                <img
                  src={`${window.location.origin}/hero2-bg.png`}
                  style={{ maxHeight: 400 }}
                />
                <a

                  class="bg-success-300 hover:bg-success-400 transition duration-300 ease-in-out ml-6 font-semibold text-white py-3 flex items-center justify-center rounded-xl px-5"
                >
                  Upload Image
                </a>
                <a

                  class="bg-red-500 hover:bg-red-500 transition duration-300 ease-in-out ml-6 font-semibold text-white py-3 flex items-center justify-center rounded-xl px-5"
                >
                  Save
                </a>
              </div>
              <div class="conversion-unit">
                <h3 class="text-lg font-medium leading-[24px] dark:text-white text-bgray-900 pb-2.5">
                  Edit Text
                </h3>
                <div class="flex space-x-3 mb-4 w-full h-[48px]">
                  <div class="relative">
                    <button
                      onclick="dateFilterAction('#usd-filter')"
                      type="button"
                      class="w-[80px] px-2.5 py-[14px] border rounded-lg  border-bgray-300 dark:border-darkblack-400 dark:bg-darkblack-500 dark:text-white flex flex-row justify-center items-center"
                    >
                      <span class="text-sm font-bold text-bgray-900 dark:text-white">
                        Heading
                      </span>
                    </button>
                  </div>
                  <div class="w-full h-full   overflow-hidden">
                    <input
                      type="text"
                      class="w-full h-full border border-bgray-300  rounded-lg focus:ring-0 dark:bg-darkblack-500 dark:border-darkblack-400 focus:border-none text-base text-bgray-900  dark:border dark:text-white"
                    />
                  </div>
                </div>
                <div class="flex space-x-3 w-full h-[48px]">
                  <div class="relative">
                    <button
                      onclick="dateFilterAction('#eur-filter')"
                      type="button"
                      class="w-[80px] px-2.5 py-[14px] border rounded-lg  border-bgray-300 dark:border-darkblack-400 dark:bg-darkblack-500 dark:text-white flex flex-row justify-center items-center"
                    >
                      <span class="text-sm font-bold text-bgray-900 dark:text-white">
                        Description
                      </span>
                    </button>
                    <div
                      id="eur-filter"
                      class="rounded-lg w-full shadow-lg bg-white dark:bg-darkblack-500 absolute right-0 z-10 top-14 overflow-hidden hidden"
                    >
                      <ul>
                        <li
                          onclick="dateFilterAction('#eur-filter')"
                          class="text-sm  text-bgray-90 cursor-pointer px-5 py-2 dark:text-white hover:bg-bgray-100 hover:dark:bg-darkblack-500 font-semibold"
                        >
                          USD
                        </li>
                        <li
                          onclick="dateFilterAction('#eur-filter')"
                          class="text-sm  text-bgray-900 cursor-pointer px-5 py-2 dark:text-white hover:bg-bgray-100 hover:dark:bg-darkblack-500 font-semibold"
                        >
                          EU
                        </li>

                        <li
                          onclick="dateFilterAction('#eur-filter')"
                          class="text-sm  text-bgray-900 cursor-pointer px-5 py-2 dark:text-white hover:bg-bgray-100 hover:dark:bg-darkblack-500  font-semibold"
                        >
                          AUD
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="w-full h-full  overflow-hidden">
                    <input
                      type="text"
                      class="w-full h-full border border-bgray-300  rounded-lg focus:ring-0 dark:bg-darkblack-500 dark:border-darkblack-400 focus:border-none text-base text-bgray-900  dark:border dark:text-white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}
