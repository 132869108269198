import React from "react";
import illustration from "../../assets/images/illustration/404.svg";

export default function Page404() {
  const [theme, setTheme] = React.useState(
    localStorage.getItem("theme") || "light"
  );

  React.useEffect(() => {
    document.documentElement.classList.toggle("dark", theme === "dark");
  }, [theme]);
  
  return (
    <section class="dark:bg-notfound-dark bg-no-repeat bg-cover bg-notfound-light">
      <div class="flex items-center justify-center min-h-screen">
        <div class="max-w-2xl mx-auto">
          <img src={illustration} alt="" />
          <div class="flex justify-center mt-10">
            <a
              href="index.html"
              class="bg-success-300 text-sm font-bold text-white rounded-lg px-10 py-3"
            >
              Go Back
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
