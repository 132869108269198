import React, { useState } from "react";
import Field from "../Components/Common/Field";
import useResponsive from "../Hooks/responsive";
import { LogoFullIcon } from "../assets/svgs";
import axios from "axios";
import { baseUrl } from "../constants";
import { useNavigate } from "react-router-dom";
import toast, { LoaderIcon } from "react-hot-toast";

const Login = () => {
  const isMobile = useResponsive()
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true)
    // Prepare the data to send in the request body
    const userData = {
      username: userName,
      password,
    };

   
    axios.post(`${baseUrl}/admin/signin`, userData)
      .then(response => {

        const token = response.data.token;
        localStorage.setItem('token', token);

        setTimeout(() => {
          navigate('/dashboard/user')
        }, 1000);
      })
      .catch(error => {
        toast.error('Invalid credentails')
        console.error(error);
        setLoading(false)
      });
  };

  return (
    <div className="login-wrap">
      <div className="logo-wrap">
        <a>
          <LogoFullIcon />
        </a>
      </div>
      <main className="login-main">
        <div className="login-form-wrap">
          <h1 className="login-heading">ADMIN LOGIN</h1>
          <Field type="text" placeholder="Username" value={userName} onChange={(e) => setUserName(e.target.value)} />
          <Field type={show ? "text" : "password"} setShow={setShow} placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)}  />
          <button className="login-page-btn" onClick={handleLogin}>{loading? <LoaderIcon/> : `Log in`}</button>

    
           {!isMobile &&  <p className="recap">
              This site is protected by reCAPTCHA and the{" "}
              <a>Google Privacy Policy</a> and <a>Terms of Service</a> apply.
            </p>}


            {isMobile && <> <p className="recap">
              This site is protected by reCAPTCHA and the </p>{" "}
              <p className="recap ss"> <a>Google Privacy Policy</a> and <a>Terms of Service</a> apply.
            </p> </>}
       
       
        </div>
      </main>
      {!isMobile &&<div className="bg-chalk"></div>}
    </div>
  );
};

export default Login;
