// import React, { useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
// import Field from "../Common/Field";

// const SetVipModal = ({
//   isModalOpen,
//   onClose,
//   SelectedUser,
//   setRefresh,
//   Refresh,
// }) => {
//   const [isOpen, setIsOpen] = React.useState(false);
//   const [user, setUser] = useState("");
//   const [period, setPeriod] = useState("");
//   const [status, setStatus] = useState("");

//   useEffect(() => {
//     setStatus(SelectedUser.plan);
//   }, [SelectedUser]);

//   const handleDropdownToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 786);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsWideScreen(window.innerWidth >= 786);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleOptionSelect = (option) => {
//     // Handle the selected option logic here
//     console.log("Selected Option:", option);
//     setStatus(option);
//     ChangeRole(option);
//     setIsOpen(false); // Close the dropdown after selecting an option
//   };

//   function TimestampAddDays(timestamp) {
//     // Create a new Date object with the provided timestamp
//     const date = new Date(timestamp * 1000);

//     // Add 30 days to the date
//     date.setDate(date.getDate() + 30);

//     // Get the components of the date
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
//     const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if necessary

//     // Format the date as mm/dd/yyyy
//     const formattedDate = `${year}-${month}-${day}`;

//     return formattedDate;
//   }

//   function TimestampToDate(timestamp) {
//     // Create a new Date object with the provided timestamp
//     const date = new Date(timestamp * 1000);

//     // Get the components of the date
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
//     const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if necessary

//     // Format the date as mm/dd/yyyy
//     const formattedDate = `${year}-${month}-${day}`;
//     return formattedDate;
//   }
//   const modalStyle = {
//     display: isModalOpen ? "block" : "none",
//     position: "fixed",
//     zIndex: 1,
//     left: 0,
//     top: 0,
//     width: "100%",
//     height: "100%",
//     overflow: "auto",
//     backgroundColor: "rgba(0,0,0,0.4)",
//     padding: "60px",
//   };

//   const inputContainerStyle = {
//     display: "flex",
//     flexDirection: "column",
//     margin: "10px",
//   };

//   const inputStyle = {
//     margin: "10px",
//     padding: "8px",
//     fontSize: "16px",
//     width: "100%",
//     boxSizing: "border-box",
//   };

//   const buttonStyle = {
//     margin: "10px",
//     padding: "10px 20px",
//     fontSize: "18px",
//     backgroundColor: "#4CAF50",
//     color: "white",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const handleSubmit = () => {
//     // Handle form submission logic here
//     console.log(`User: ${user}, Period: ${period}, Status: ${status}`);
//     ChangeRole(status);
//   };

//   const ChangeRole = (status) => {
//     // setLoading(true);
//     fetch(`https://celebri-backend.onrender.com/users/updatePlan`, {
//       method: "POST",
//       headers: {
//         "content-type": "application/json",
//         accept: "application/json",
//       },
//       body: JSON.stringify({
//         adminName: "admin",
//         userId: SelectedUser._id,
//         newPlan: status,
//       }),
//     })
//       .then((response) => response.json())
//       .then((res) => {
//         if (res.success === true) {
//           toast.success(res.message);
//           setRefresh(Refresh + 1);
//           onClose();
//         }
//         // setLoading(false);
//       })
//       .catch(() => {
//         toast.error("Something goes wrong!");
//       });
//   };

//   return (
//     <div style={modalStyle}>
//       <div
//         style={{
          
//           backgroundColor: "#fefefe",
//           margin: isWideScreen ? "5% auto" : "15% auto",
//           padding: "20px",
//           border: "1px solid #888",
//           width: isWideScreen ? "40%" : "100%",
//         }}
//       >
//         <span style={{ float: "right", cursor: "pointer" }} onClick={onClose}>
//           &times;
//         </span>
//         <h2>Set User Status</h2>
//         <div style={inputContainerStyle}>
//           <Field
//             placeholder="User"
//             value={
//               status == "user"
//                 ? "Normal User"
//                 : status == "free"
//                 ? "Free User"
//                 : "Celebri User"
//             }
//             readOnly={true}
//           />
//         </div>
//         <div style={inputContainerStyle}>
//           <label>Period: (From)</label>
//           <Field
//             type="date"
//             placeholder="Date From"
//             onChange={(e) => console.log(e.target.value)}
//             readOnly={true}
//             value={TimestampToDate(SelectedUser?.stripeCustomer?.created)}
//           />
//         </div>
//         <div style={inputContainerStyle}>
//           <label>Period: (To)</label>
//           <Field
//             type="date"
//             value={TimestampAddDays(SelectedUser?.stripeCustomer?.created)}
//             readOnly={true}
//           />{" "}
//         </div>
//         <div style={inputContainerStyle}>
//           <div className="relative inline-block">
//             <button
//               onClick={handleDropdownToggle}
//               type="button"
//               className="p-3.5 border border-bgray-300 dark:border-darkblack-400 flex space-x-1 items-center rounded-lg overflow-hidden"
//             >
//               {/* <span className="text-sm font-medium text-bgray-900 dark:text-white"> */}
//               Change user role
//               {/* </span> */}
//               <span>
//                 <svg
//                   className="stroke-bgray-900 dark:stroke-white"
//                   width="16"
//                   height="17"
//                   viewBox="0 0 16 17"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     d="M4 6.5L8 10.5L12 6.5"
//                     strokeWidth="1.5"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                   />
//                 </svg>
//               </span>
//             </button>

//             {isOpen && (
//               <div className="absolute mt-2 border border-bgray-300 dark:border-darkblack-400 rounded-lg overflow-hidden shadow-lg">
//                 <div
//                   className="cursor-pointer px-4 py-2 bg-white hover:bg-gray-100 dark:bg-darkgray-900 dark:hover:bg-darkgray-800"
//                   onClick={() => handleOptionSelect("user")}
//                 >
//                   Normal User
//                 </div>
//                 <div
//                   className="cursor-pointer px-4 py-2 bg-white hover:bg-gray-100 dark:bg-darkgray-900 dark:hover:bg-darkgray-800"
//                   onClick={() => handleOptionSelect("vip")}
//                 >
//                   Celebri User
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//         <button style={buttonStyle} onClick={handleSubmit}>
//           Submit
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SetVipModal;
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Field from "../Common/Field";

const SetVipModal = ({
  isModalOpen,
  onClose,
  SelectedUser,
  setRefresh,
  Refresh,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState("");
  const [period, setPeriod] = useState("");
  const [status, setStatus] = useState(SelectedUser.plan); // Initialize with SelectedUser's plan

  useEffect(() => {
    setStatus(SelectedUser.plan);
  }, [SelectedUser]);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 786);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 786);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOptionSelect = (option) => {
    // Handle the selected option logic here
    console.log("Selected Option:", option);
    setStatus(option);
    setIsOpen(false); // Close the dropdown after selecting an option
  };

  function TimestampAddDays(timestamp) {
    const date = new Date(timestamp * 1000);
    date.setDate(date.getDate() + 30);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function TimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const modalStyle = {
    display: isModalOpen ? "block" : "none",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: "60px",
  };

  const inputContainerStyle = {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
  };

  const inputStyle = {
    margin: "10px",
    padding: "8px",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    margin: "10px",
    padding: "10px 20px",
    fontSize: "18px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };

  const handleSubmit = () => {
    console.log(`User: ${user}, Period: ${period}, Status: ${status}`);
    ChangeRole(status);
  };

  const ChangeRole = (status) => {
    fetch(`https://celebri-backend.onrender.com/users/updatePlan`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        adminName: "admin",
        userId: SelectedUser._id,
        newPlan: status,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success === true) {
          toast.success(res.message);
          setRefresh(Refresh + 1);
          onClose();
        }
      })
      .catch(() => {
        toast.error("Something goes wrong!");
      });
  };

  return (
    <div style={modalStyle}>
      <div
        style={{
          backgroundColor: "#fefefe",
          margin: isWideScreen ? "5% auto" : "15% auto",
          padding: "20px",
          border: "1px solid #888",
          width: isWideScreen ? "40%" : "100%",
        }}
      >
        <span style={{ float: "right", cursor: "pointer" }} onClick={onClose}>
          &times;
        </span>
        <h2>Set User Status</h2>
        <div style={inputContainerStyle}>
          <Field
            placeholder="User"
            value={
              status === "user"
                ? "Normal User"
                : status === "free"
                ? "Free User"
                : "Celebri User"
            }
            readOnly={true}
          />
        </div>
        <div style={inputContainerStyle}>
          <label>Period: (From)</label>
          <Field
            type="date"
            placeholder="Date From"
            onChange={(e) => console.log(e.target.value)}
            readOnly={true}
            value={TimestampToDate(SelectedUser?.stripeCustomer?.created)}
          />
        </div>
        <div style={inputContainerStyle}>
          <label>Period: (To)</label>
          <Field
            type="date"
            value={TimestampAddDays(SelectedUser?.stripeCustomer?.created)}
            readOnly={true}
          />
        </div>
        <div style={inputContainerStyle}>
          <div className="relative inline-block">
            <button
              onClick={handleDropdownToggle}
              type="button"
              className="p-3.5 border border-bgray-300 dark:border-darkblack-400 flex space-x-1 items-center rounded-lg overflow-hidden"
            >
              {status === "user" ? "Normal User" : status === "vip" ? "Celebri User" : "Change user role"}
              <span>
                <svg
                  className="stroke-bgray-900 dark:stroke-white"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6.5L8 10.5L12 6.5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>

            {isOpen && (
              <div className="absolute mt-2 border border-bgray-300 dark:border-darkblack-400 rounded-lg overflow-hidden shadow-lg">
                <div
                  className="cursor-pointer px-4 py-2 bg-white hover:bg-gray-100 dark:bg-darkgray-900 dark:hover:bg-darkgray-800"
                  onClick={() => handleOptionSelect("user")}
                >
                  Normal User
                </div>
                <div
                  className="cursor-pointer px-4 py-2 bg-white hover:bg-gray-100 dark:bg-darkgray-900 dark:hover:bg-darkgray-800"
                  onClick={() => handleOptionSelect("vip")}
                >
                  Celebri User
                </div>
              </div>
            )}
          </div>
        </div>
        <button style={buttonStyle} type="button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default SetVipModal;

