import React, { useState } from "react";
import Field from "../Components/Common/Field";
import { toast } from "react-hot-toast";
import RichTextEditor from "react-rte";

const ArticlesToPost = () => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [ImageFile, setImageFile] = useState("");

  const [imageDescription, setImageDescription] = useState("");
  const [blogContent, setBlogContent] = useState("");

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  );

  const style = {
    border: "1px solid #ccc",
    padding: "10px",
    height: "500px",
  };
  const onChange = (value) => {
    setEditorValue(value);
    console.log(editorValue.toString("html"));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const createBlogPost = (status) => {
    // setLoading(true);

    console.log("sdds");
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("type", imageDescription);
    formdata.append("body", editorValue.toString("html"));
    formdata.append("adminName", "admin");
    formdata.append("image", ImageFile);

    fetch(`https://celebri-backend.onrender.com/users/create/blogs`, {
      method: "POST",

      body: formdata,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success === true) {
          toast.success(res.message);
          setTitle("");
          setImageDescription("");
          setImage("");
          setImageFile("");
          setEditorValue(RichTextEditor.createEmptyValue());
        }
        // setLoading(false);
      })
      .catch(() => {
        toast.error("Something goes wrong!");
      });
  };

  return (
    <div
      style={{
        maxWidth: "900px",
        margin: "120px auto 0 auto",
        padding: "20px",
      }}
    >
      <h1 className="login-heading">Create a Blog Post</h1>
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="image">Upload Image:</label>
        <input type="file" id="image" onChange={handleImageChange} />
        {image && <img src={image} alt="Uploaded" width="200" />}
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Field
          type="text"
          placeholder="Blog type description:"
          onChange={(e) => setImageDescription(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Field
          type="text"
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <RichTextEditor style={style} value={editorValue} onChange={onChange} />
      </div>
      <button
        onClick={() => {
          createBlogPost();
          // Handle form submission here (e.g., send data to server)
        }}
        style={{
          padding: "10px 20px",
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Create Blog Post
      </button>
    </div>
  );
};

export default ArticlesToPost;
