import React from "react";

import totalEarnIcon from '../../../assets/images/icons/total-earn.svg'
import membersAvatar from "../../../assets/images/avatar/members-2.png";
import CommonCard from "../../Common/CommonCard";
import Revenue from "../Admin/Revenue";

export default function Home() {
  return (
    <main class="w-full xl:px-12 px-6 pb-6 xl:pb-12 sm:pt-[156px] pt-[100px]">
      <div class="flex  space-x-[48px]" style={{flexDirection: 'column'}}>
        <section class="flex-1 mb-0 mb-6">
          <div class="w-full mb-[24px]">
            <div class="grid lg:grid-cols-3 grid-cols-1 gap-[24px]">
              <CommonCard title={'Total earnings'} total={'7,245.00'}/>
              <CommonCard title={'Total Spending'} total={'7,245.00'}/>
              <CommonCard title={'Spending Goal'} total={'7,245.00'}/>
            </div>
          </div>
        </section>

        <Revenue/>
      </div>
    </main>
  );
}
