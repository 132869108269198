import React, { useState } from "react";
import Field from "../Components/Common/Field";
const Annoucement = () => {
  const [image, setImage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "120px auto 0 auto",
        padding: "20px",
      }}
    >
      <h1 className="login-heading">Create Annoucement</h1>

      <div style={{ marginBottom: "20px" }}>
        <Field type="text" placeholder="Annoucement Title" />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Field type="text" placeholder="Annoucement" />
      </div>

      <br/>

      <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'start', gap: 10}}>

      <div>
        <input
          className="lomda"
          type="file"
          id="file"
          onChange={handleImageChange}
        />
        <label for="file" class="btn-2">
          upload
        </label>

        {image && <img src={image} alt="Uploaded" width="200" />}
      </div>

      <button
        style={{
          padding: "16.2px 20px",

          backgroundColor: "#f15d22",
          color: "white",
          border: "none",
          borderRadius: "50px",
          cursor: "pointer"
        }}
      >
        Send Annoucement
      </button>

      </div>
    </div>
  );
};

export default Annoucement;
