import React from "react";
import useResponsive from '../../Hooks/responsive'
import {
  DashboardIcon2,
  HeaderIcon,
  LogoFullIcon,
  LogoutIcon,
  StatisticsIcon2,
  UserIcon2,
} from "../../assets/svgs";


import { Link, useNavigate } from "react-router-dom";

export default function SideBar({ toggleActive }) {
  return (
    <>
      <aside class="block xl:block sm:hidden sidebar-wrapper w-[308px] fixed top-0 bg-white dark:bg-darkblack-600 h-full z-30">
        <SidebarHeader toggleActive={toggleActive} />
        <SidebarBody  toggleActive={toggleActive}/>
      </aside>
      <div
        style={{ zIndex: 25 }}
        class="aside-overlay block sm:hidden w-full h-full fixed left-0 top-0 bg-black bg-opacity-30"
      ></div>
    </>
  );
}

const SidebarHeader = ({ toggleActive }) => {
  const isMobile = useResponsive();

  const checkdevice = ()=>{
    if(isMobile){
      toggleActive()
    }
  }
  return (
    <div className="sidebar-header relative border-r border-b border-r-[#F7F7F7] border-b-[#F7F7F7] dark:border-darkblack-400 w-full h-[108px] flex items-center pl-[50px] z-30">
      <Link to={'/dashboard/user'} onClick={()=>checkdevice()}>
        <LogoFullIcon/>
      </Link>
      {isMobile ?  <button
        type="button"
        className="drawer-btn absolute right-0 top-auto"
        title="Ctrl+b"
        onClick={toggleActive}
      >
        <span>
          <HeaderIcon />
        </span> 
       </button> : null}
     
    </div>
  );
};

const List1 = ({toggleActive}) => {
  const isMobile = useResponsive();

  const navigate = useNavigate();
  const checkdevice = ()=>{
    if(isMobile){
      toggleActive()
    }
  }
   return (
    <ul className="mt-2.5">
      {/* <li className="item py-[11px] text-bgray-900 dark:text-white" onClick={() => navigate('/dashboard/app')}>
        <a style={{ cursor: "pointer" }}>
          <div className="flex items-center justify-between">
            <div className="flex space-x-2.5 items-center">
              <span className="item-ico">
                <DashboardIcon2 />
              </span>
              <span className="item-text text-lg font-medium leading-none">
                Dashboards
              </span>
            </div>
          </div>
        </a>
      </li>
    
      <li className="item py-[11px] text-bgray-900 dark:text-white" onClick={() => navigate('/dashboard/cms')}>
        <a style={{ cursor: "pointer" }}>
          <div className="flex items-center justify-between">
            <div className="flex space-x-2.5 items-center">
              <span className="item-ico">
                <StatisticsIcon2 />
              </span>
              <span className="item-text text-lg font-medium leading-none">
                CMS
              </span>
            </div>
          </div>
        </a>
      </li> */}
      <li className="item py-[11px] text-bgray-900 dark:text-white" onClick={() =>{ navigate('/dashboard/user');checkdevice()}}>
        <a style={{ cursor: "pointer" }}>
          <div className="flex items-center justify-between">
            <div className="flex space-x-2.5 items-center">
              <span className="item-ico">
                <UserIcon2 />
              </span>
              <span className="item-text text-lg font-medium leading-none">
                Users
              </span>
            </div>
          </div>
        </a>
      </li>
      {/* <li className="item py-[11px] text-bgray-900 dark:text-white" onClick={() => navigate('/dashboard/annoucement')}>
        <a style={{ cursor: "pointer" }}>
          <div className="flex items-center justify-between">
            <div className="flex space-x-2.5 items-center">
              <span className="item-ico">
                <StatisticsIcon2 />
              </span>
              <span className="item-text text-lg font-medium leading-none">
                News Annoucement
              </span>
            </div>
          </div>
        </a>
      </li> */}
      <li className="item py-[11px] text-bgray-900 dark:text-white" onClick={() => {navigate('/dashboard/couponGenerator');checkdevice()}}>
        <a style={{ cursor: "pointer" }}>
          <div className="flex items-center justify-between">
            <div className="flex space-x-2.5 items-center">
              <span className="item-ico">
                <StatisticsIcon2 />
              </span>
              <span className="item-text text-lg font-medium leading-none">
                Coupon Generator
              </span>
            </div>
          </div>
        </a>
      </li>
      <li className="item py-[11px] text-bgray-900 dark:text-white" onClick={() => {navigate('/dashboard/articles-to-post');checkdevice()}}>
          <a style={{ cursor: "pointer" }}>
          <div className="flex items-center justify-between">
            <div className="flex space-x-2.5 items-center">
              <span className="item-ico">
                <DashboardIcon2 />
              </span>
              <span className="item-text text-lg font-medium leading-none">
                Articles to post
              </span>
            </div>
          </div>
        </a>
      </li>
      <li className="item py-[11px] text-bgray-900 dark:text-white" onClick={() => {navigate('/dashboard/site-setting');;checkdevice()}}>
        <a style={{ cursor: "pointer" }}>
          <div className="flex items-center justify-between">
            <div className="flex space-x-2.5 items-center">
              <span className="item-ico">
                <StatisticsIcon2 />
              </span>
              <span className="item-text text-lg font-medium leading-none">
               Site Setting
              </span>
            </div>
          </div>
        </a>
      </li>
    </ul>
  );
};



const Logout = () => {
  const navigate = useNavigate();

  return (
    <ul className="mt-2.5">

      <li
        className="item py-[11px] text-bgray-900 dark:text-white"
        onClick={() => {
          localStorage.clear();
          navigate("/signin");
        }}
      >
        <a href="#">
          <div className="flex items-center justify-between">
            <div className="flex space-x-2.5 items-center">
              <span className="item-ico">
                <LogoutIcon />
              </span>
              <span className="item-text text-lg font-medium leading-none">
                Logout
              </span>
            </div>
          </div>
        </a>
      </li>
    </ul>
  );
};

const MenuList = ({toggleActive}) => {
  return (
    <ul>
      <li>
        <h4 className="text-sm font-medium dark:text-bgray-50 text-bgray-700 border-b dark:border-darkblack-400 border-bgray-200 leading-7">
          Menu
        </h4>
         <List1 toggleActive={toggleActive} />
      </li>

      <li>
        <h4 className="text-sm font-medium text-bgray-700 dark:text-bgray-50 border-b border-bgray-200 dark:border-darkblack-400 leading-7">
          Others
        </h4>
        <Logout />
      </li>
    </ul>
  );
};

const SidebarBody = ({toggleActive}) => {
  return (
    <div className="sidebar-body pl-[48px] pt-[14px] w-full relative z-30 h-screen overflow-style-none overflow-y-scroll pb-[200px]">
      <div className="nav-wrapper pr-[50px] mb-[36px]">
        <div className="item-wrapper mb-5">
          <MenuList toggleActive = {toggleActive}/>
        </div>
      </div>
    </div>
  );
};
