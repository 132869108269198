import React from "react";
import SideBar from "../Components/Common/SideBar";
import ResponsiveSideBar from "../Components/Common/ResponsiveSideBar";
import ResponsiveHeader from "../Components/Common/ResponsiveHeader";
import Header from "../Components/Common/Header";
import Home from "../Components/Dashboard/Pages/Home";
import { Navigate, Outlet } from "react-router-dom";

export default function DashboardAdmin() {

  
  const [isActive, setIsActive] = React.useState(true);

  const toggleActive = () => {
    setIsActive(!isActive);
  };


 

  return (
    <div className={`w-full layout-wrapper ${isActive ? 'active' : ''}`}>
      <div class="w-full flex relative">
        <SideBar toggleActive={toggleActive} />
        <ResponsiveSideBar  />
        <div class="body-wrapper dark:bg-darkblack-500 flex-1 overflow-x-hidden">
          <ResponsiveHeader toggleActive={toggleActive} />
          <Header toggleActive={toggleActive} />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
