import React from "react";
import { EyeClose, EyeOpen } from "../../assets/svgs";

export default function Field({
  type,
  placeholder,
  stickyLabel,
  value,
  onChange,
  setShow,
  readOnly,
  name,
}) {
  return (
    <div className="field">
      {stickyLabel && <label>linktr.ee/</label>}
      <div style={{ flexGrow: "1" }}>
        <input
          type={type}
          placeholder={placeholder}
          style={{ width: "100%" }}
          value={value}   
          onChange={onChange}
          name={name}
          readOnly={readOnly}
        />
        {type === "password" && setShow && (
          <span
            style={{
              position: "absolute",
              right: 15,
              top: 17,
              cursor: "pointer",
            }}
            onClick={() => setShow((x) => !x)}
          >
            <EyeOpen />
          </span>
        )}
        {type === "text" && setShow && (
          <span
            style={{
              position: "absolute",
              right: 15,
              top: 17,
              cursor: "pointer",
            }}
            onClick={() => setShow((x) => !x)}
          >
            <EyeClose />
          </span>
        )}
      </div>
    </div>
  );
}

export function TextArea({ placeholder, value, onChange }) {
  return (
    <textarea
      className="rounded-lg mb-5 bg-[#F5F5F5] dark:bg-darkblack-500 dark:text-white p-4 border-0 focus:border focus:ring-0 focus:border-red-300 w-full placeholder:font-medium text-base"
      placeholder={placeholder}
      value={value || ""}
      onChange={onChange}
    ></textarea>
  );
}
