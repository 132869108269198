import React from 'react'
// import avatarImage from "../assets/images/avatar/mh.png";
import {useLocation} from "react-router-dom";
const ViewPayments = () => {
    
    const location = useLocation();
    const receivedData = location.state?.data;
    console.log(receivedData)
    // function timeAgo(timestamp) {
    //     const currentDate = new Date();
    //     const createdAt = new Date(timestamp);
    //     const timeDifference = currentDate - createdAt;
    
    //     const minute = 60 * 1000;
    //     const hour = minute * 60;
    //     const day = hour * 24;
    //     const week = day * 7;
    
    //     if (timeDifference < minute) {
    //       return Math.floor(timeDifference / 1000) + " seconds ago";
    //     } else if (timeDifference < hour) {
    //       return Math.floor(timeDifference / minute) + " minutes ago";
    //     } else if (timeDifference < day) {
    //       return Math.floor(timeDifference / hour) + " hours ago";
    //     } else if (timeDifference < week) {
    //       return Math.floor(timeDifference / day) + " days ago";
    //     } else {
    //       return Math.floor(timeDifference / week) + " weeks ago";
    //     }
    //   }
    return (
        <>
            <div style={{ marginTop: "120px" }}>
                <div className="login-form-wrap">
                    <h1 className="login-heading" style={{marginBottom: 40}}>Payment Details</h1>
                    <br/>
                    <aside class=" w-full bg-white dark:bg-darkblack-600 rounded-lg px-12 pb-7" style={{boxShadow:'0 0 10px 0 rgba(0,0,0,0.1)', marginBottom: 20, marginTop: 40}}>
                        <header class="flex flex-col items-center text-center -mt-8 pb-7">
                            <img
                                src={"https://www.w3schools.com/howto/img_avatar.png"}
                                class="rounded-lg"
                                alt=""
                                style={{transform: 'translateY(-40px)', width: 90}}
                            />
                            <h3 class="text-xl font-bold text-bgray-700 dark:text-white ">
                                {receivedData.givenName}
                            </h3>
                            <p class="text-base font-medium text-bgray-500 dark:text-white">
                            </p>
                        </header>
                        <ul class="py-7 border-t border-b border-gray-200 dark:border-darkblack-400 space-y-6">
                            <li class="flex justify-between">
                                <span class="font-medium text-gray-500 text-sm dark:text-white">
                                    Payment Method
                                </span>
                                <span class="text-sm font-semibold text-bgray-900 dark:text-white">
                                    Stripe
                                </span>
                            </li>
                            <li class="flex justify-between">
                                <span class="font-medium text-gray-500 text-sm dark:text-white">
                                    Email
                                </span>
                                <span class="text-sm font-semibold text-bgray-900 dark:text-white">
                                   {receivedData.email}
                                </span>
                            </li>
                            <li class="flex justify-between">
                                <span class="font-medium text-gray-500 text-sm dark:text-white">
                                    Payment Type
                                </span>
                                <span class="text-sm font-semibold text-bgray-900 dark:text-white">
                                    {receivedData?.plan}
                                </span>
                            </li>
                            {console.log("receivedData?.userSubscription?.plan?.amount", receivedData?.userSubscription?.plan?.amount)}
                            <li class="flex justify-between">
                                <span class="font-medium text-gray-500 text-sm dark:text-white">
                                    Payment Price
                                </span>
                                <span class="text-sm font-semibold text-bgray-900 dark:text-white">
                                    ${receivedData?.userSubscription?.plan?.amount ? receivedData?.userSubscription?.plan?.amount/100 : 0}
                                </span>
                            </li>
                            {/* <li class="flex justify-between">
                                <span class="font-medium text-gray-500 text-sm dark:text-white">
                                    Credit Card Details
                                </span>
                                <span class="text-sm font-semibold text-bgray-900 dark:text-white">
                                    1234 5678 1234 3456
                                </span>
                            </li> */}
                        </ul>

                    </aside>
                </div>
            </div>
        </>
    )
}
export default ViewPayments